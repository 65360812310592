<template>
	<div>
		<div class="syitem" v-for="(item,idx) in DataList" @click="ziXun(item)">
			{{item.cdate}} 收益 ¥{{item.symoney}}
		</div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				keyword: "",
				DataList: []

			}
		},

		mounted() {

			this.getList();
		},
		methods: {
			getList() {
				$("#loading").show()
				this.$store.dispatch("GET", {
					url: "/api/zixun/?action=zjsylist"
				}).then(result => {
					for(let item of result.data.rows){
						item.cdate = this.getCdate(item.cdate)
					}
					this.DataList = result.data.rows;
					$("#loading").hide()
				})
			},
			searchData() {
				sessionStorage.setItem("zxkeyword", this.keyword)
				this.getList()
			},
			ziXun(item) {
				this.$router.push("/zxresult/" + item.id)
			},
			getCdate(e) {
				let d = new Date(e);
				let y = d.getFullYear();
				let m = d.getMonth() + 1;
				let day = d.getDate();
				let hour = d.getHours()
				let min = d.getMinutes()
				if (m < 10) m = "0" + m
				if (day < 10) day = "0" + day
				if (hour < 10) hour = "0" + hour
				if (min < 10) min = "0" + min
			
				return y + "-" + m + "-" + day + " " + hour + ":" + min
			},
		}
	}
</script>

<style scoped="scoped">
	.syitem{ padding: 15px; border-bottom: 1px solid #f6f6f6;}
</style>
